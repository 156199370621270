import { render, staticRenderFns } from "./Billing.vue?vue&type=template&id=6c2cbd30&scoped=true&"
import script from "./Billing.vue?vue&type=script&lang=js&"
export * from "./Billing.vue?vue&type=script&lang=js&"
import style0 from "./Billing.vue?vue&type=style&index=0&id=6c2cbd30&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c2cbd30",
  null
  
)

export default component.exports